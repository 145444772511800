import { border } from 'models/TenderMarketplace/styles/tenderCardStyles';
import theme from 'shared/theme';

export const cardStyle = {
  height: '100%',
  pt: 0,
  pb: 0,
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: { p: '5px' },
};
export const mainGrid = {
  pb: 2,
  pl: 0,
  pt: 2,
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: { p: '15px' },
};
export const categoryName = {
  display: 'block',
  pb: 2.5,
  pr: 0.5,
  cursor: 'pointer',
  width: 'fit-content',
  wordWrap: 'break-word',
  ':hover': {
    opacity: 0.7,
  },
  '@media (max-width: 1050px)': {
    fontSize: '28px',
  },
  '@media (max-width: 550px)': {
    fontSize: '29px',
  },
  '@media (max-width: 400px)': {
    fontSize: '20px',
  },
};
export const gridCategories = {
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
};
export const categoriesNames = {
  pb: 1,
  mr: 2.5,
  cursor: 'pointer',
  width: 'fit-content',
  fontFamily: 'Winston SemiBold',
  // wordWrap: 'break-word',
  wordBreak: 'break-word',
  ':hover': {
    opacity: 0.7,
  },
};
export const boxContainer = {
  textAlign: 'center',
  p: 0.5,
  mr: 1,
  mb: 2,
};

export const numberOrange = {
  lineHeight: 1.5,
  fontSize: '18px',
  fontWeight: 900,
  background: 'transparent',
  color: theme.palette.secondary.dark,
  border: `1px solid ${theme.palette.secondary.dark}`,
  borderRadius: 3,
  [theme.breakpoints.down('sm')]: { fontSize: '14px' },
};
export const numberGreen = {
  lineHeight: 1.5,
  cursor: 'default',
  fontSize: '18px',
  fontWeight: 900,
  background: 'transparent',
  color: theme.palette.success.main,
  border: `1px solid ${theme.palette.success.main}`,
  borderRadius: 3,
  [theme.breakpoints.down('sm')]: { fontSize: '14px' },
};
export const text = {
  fontSize: '14px',
  fontWeight: 900,
  [theme.breakpoints.down('sm')]: { fontSize: '13px' },
};
export const sellBtn = {
  fontSize: '14px',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.grey[900],
  mt: 0.5,
  // mr: 2,
  minWidth: '200px',
  // fontFamily: 'Winston SemiBold',
  [theme.breakpoints.down('sm')]: { mr: 0 },
};

export const findBtn = {
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.grey[900],
  mt: 0.5,
  // mr: 2,
  minWidth: '200px',
  fontSize: '14px',
  // fontFamily: 'Winston SemiBold',
  [theme.breakpoints.down('sm')]: { mr: 0 },
};
export const gridImages = {
  mr: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
};
export const images = {
  width: '100%',
  height: '200px',
  borderRadius: '12px 8px  8px 12px',
};
export const smallScreenGrid = {
  mb: 1,
  [theme.breakpoints.down('sm')]: { justifyContent: 'center' },
};

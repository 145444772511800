import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useSession } from 'next-auth/react';
import { useContext, useEffect, useState } from 'react';
import { PageContext } from 'store/pagecontext';
import { Company } from 'services/interfaces/Company';
import WebApi from 'services/index';
import { CarryingCapacity } from 'services/interfaces/CarryingCapacity';
import { LiftingSpeed } from 'services/interfaces/LiftingSpeed';
import { MachineRoom } from 'services/interfaces/MachineRoom';
import { Measure } from 'services/interfaces/Measure';

export const useHelperMainPage = () => {
  const [userCompany, setUserCompany] = useState<Company[] | []>([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const router = useRouter();
  const { t } = useTranslation('common');
  const {
    measureUnit,
    machineRoom,
    carryingCapacity,
    liftingSpeed,
  }: {
    measureUnit: Measure[];
    machineRoom: MachineRoom[];
    carryingCapacity: CarryingCapacity[];
    liftingSpeed: LiftingSpeed[];
  } = useContext(PageContext);

  const { data: session } = useSession();
  interface CategoryImages {
    [alias: string]: string;
  }

  const categoryImages: CategoryImages = {
    specialMachinery: 'images/specialMachinery.png',
    realty: 'images/realty.png',
    buildingMaterial: 'images/buildingMaterial.png',
    product: 'images/product.png',
    equipment: 'images/equipment.png',
    workService: 'images/workService.png',
  };

  useEffect(() => {
    const findCompany = async () => {
      const userCompanyData = await WebApi(session).Company.getList({
        userId: session?.user?.id,
      });
      setUserCompany(userCompanyData);
    };

    if (session) {
      findCompany();
    }
  }, [session]);

  const onOpenDrawer = (category: string) => {
    setSelectedCategory(category);
    setTimeout(() => {
      setOpenDrawer(true);
    }, 500);
  };
  return {
    router,
    t,
    categoryImages,
    session,
    measureUnit,
    machineRoom,
    carryingCapacity,
    liftingSpeed,
    onOpenDrawer,
    openDrawer,
    userCompany,
    selectedCategory,
  };
};
